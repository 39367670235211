<template>
  <div class="box">
    <div class="circle">
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    </div>
    <h1>微信认证成功</h1>
    <p>恭喜您，您已成功完成微信认证。</p>
    <p>您现在可以享受更多功能了。</p>
    <!-- <button @click="logout">退出</button> -->
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      // 这里可以添加退出操作，例如跳转到登录页面
      console.log('用户点击了退出按钮');
    }
  }
};
</script>

<style scoped>
.box {
  text-align: center;
  margin-top: 50px;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #00cc66;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.checkmark {
  width: 70px;
  height: 70px;
  /* transform: rotate(45deg); */
}

.checkmark-circle {
  stroke-width: 2;
  stroke: #fff;
  fill: none;
}

.checkmark-check {
  stroke-width: 2;
  stroke: #fff;
  fill: none;
}
</style>
